/**
 * @generated SignedSource<<87c80f9f16ad11188785f37903b10199>>
 * @relayHash acd8e44079ee9bca261e35debdcdb682
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/505.0.0-2025-04-02T17:54:06.053Z/DesktopSideBarNavRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopSideBarNavRefetchQuery$variables = {
  customBannerText?: string | null;
  dotComHost: string;
  hasSellerPk: boolean;
  hyperwalletLink: string;
  renderNotifications?: boolean | null;
  sellerPk: string;
  userId?: string | null;
};
export type DesktopSideBarNavRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DesktopSideBarNav_viewer">;
  };
};
export type DesktopSideBarNavRefetchQuery = {
  response: DesktopSideBarNavRefetchQuery$data;
  variables: DesktopSideBarNavRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customBannerText"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dotComHost"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasSellerPk"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hyperwalletLink"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "renderNotifications"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sellerPk"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "href",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eventName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interactionType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideSideNavTitle",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataTn",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DesktopSideBarNavRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "renderNotifications",
                "variableName": "renderNotifications"
              }
            ],
            "kind": "FragmentSpread",
            "name": "DesktopSideBarNav_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DesktopSideBarNavRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "customBannerText",
                "variableName": "customBannerText"
              },
              {
                "kind": "Variable",
                "name": "dotComHost",
                "variableName": "dotComHost"
              },
              {
                "kind": "Variable",
                "name": "hyperwalletLink",
                "variableName": "hyperwalletLink"
              },
              {
                "kind": "Variable",
                "name": "sellerPk",
                "variableName": "sellerPk"
              },
              (v1/*: any*/)
            ],
            "concreteType": "DealerNavBar",
            "kind": "LinkedField",
            "name": "dealerNavBar",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NavBarButton",
                "kind": "LinkedField",
                "name": "buttons",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NavBarLinks",
                "kind": "LinkedField",
                "name": "links",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "desktopSideNav",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isNavBarElement"
                      },
                      (v9/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NavBarListItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v9/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v8/*: any*/)
                                ],
                                "type": "NavBarElement",
                                "abstractKey": "__isNavBarElement"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "NavBarList",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "condition": "renderNotifications",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "notifications",
                    "args": null,
                    "concreteType": "DealerNavBarBadges",
                    "kind": "LinkedField",
                    "name": "badges",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "listings",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "orders",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "messages",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recommendations",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "condition": "hasSellerPk",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "sellerId",
                    "variableName": "sellerPk"
                  }
                ],
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RepData",
                    "kind": "LinkedField",
                    "name": "sellerRep",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vertical",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v1/*: any*/)
                    ],
                    "concreteType": "CreateListingOption",
                    "kind": "LinkedField",
                    "name": "createListingOptions",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VatConfiguration",
                    "kind": "LinkedField",
                    "name": "vatConfiguration",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priceBookName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerProfile",
                    "kind": "LinkedField",
                    "name": "sellerProfile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "company",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/505.0.0-2025-04-02T17:54:06.053Z/DesktopSideBarNavRefetchQuery",
    "metadata": {},
    "name": "DesktopSideBarNavRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "17cb5edf14840054b0ba1d7c870a251a";

export default node;
