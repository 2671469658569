import { type FC, type ReactNode } from 'react';
import { useContext, useState, useEffect, useCallback, useMemo, createContext } from 'react';
import { getAbTestV2 } from 'dibs-ab-tests/exports/clientAbTestV2';
import debounce from 'lodash/debounce';

export const DEALER_DESKTOP_MIN_WIDTH = 1121;

export const isInSideBarNavTest = getAbTestV2('side-bar-nav')?.variant === 'variant';

const checkShowSideBarNav = (): boolean =>
    window.innerWidth >= DEALER_DESKTOP_MIN_WIDTH && isInSideBarNavTest;

type SideBarNavTestContextType = { showSideBarNav: boolean };

export const SideBarNavTestContext = createContext<SideBarNavTestContextType>({
    showSideBarNav: false,
});

export const SideBarNavTestProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [showSideBarNav, setShowSideBarNav] = useState(checkShowSideBarNav());

    const debouncedSetShowSideBarNav = useMemo(
        () => debounce(() => setShowSideBarNav(checkShowSideBarNav()), 100),
        []
    );

    const handleResize = useCallback(() => {
        debouncedSetShowSideBarNav();
    }, [debouncedSetShowSideBarNav]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return (
        <SideBarNavTestContext.Provider value={{ showSideBarNav }}>
            {children}
        </SideBarNavTestContext.Provider>
    );
};

export const useSideBarNavTestContext = (): SideBarNavTestContextType => {
    const context = useContext(SideBarNavTestContext);
    if (!context) {
        throw new Error('SideBarNavTestProvider was not found');
    }
    return context;
};
