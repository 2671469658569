/**
 * @generated SignedSource<<8237cfcb356e7fc86c0ba8392f36ce95>>
 * @relayHash e459f4fc16e6dba8b370dbf9e089de83
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/505.0.0-2025-04-02T17:54:06.053Z/trackUserEventMutation

import { ConcreteRequest } from 'relay-runtime';
export type UserTrackingMutationInput = {
  clientMutationId?: string | null;
  eventType: string;
  items?: ReadonlyArray<UserTrackingItemInputType> | null;
  pageId?: string | null;
  searchQuery?: string | null;
  sessionId?: string | null;
  userId: string;
};
export type UserTrackingItemInputType = {
  id: string;
  price: UserTrackingCostInputType;
  quantity: number;
};
export type UserTrackingCostInputType = {
  amount: number;
  currency?: string | null;
};
export type trackUserEventMutation$variables = {
  input: UserTrackingMutationInput;
};
export type trackUserEventMutation$data = {
  readonly userTrackingMutation: {
    readonly __typename: "UserTrackingMutationPayload";
  } | null;
};
export type trackUserEventMutation = {
  response: trackUserEventMutation$data;
  variables: trackUserEventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserTrackingMutationPayload",
    "kind": "LinkedField",
    "name": "userTrackingMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trackUserEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trackUserEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/505.0.0-2025-04-02T17:54:06.053Z/trackUserEventMutation",
    "metadata": {},
    "name": "trackUserEventMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "631a04e3b92a5a0e4190ab2edd6e2279";

export default node;
